import React from "react";
import Modal from "../../components/Modal";
import i18n from "../../i18n";

export default class DuplicatesModal extends Modal {

  componentDidMount() {
    window.webviewEventsListeners.backPress.push(() => {
      this.dismiss();
      return true;
    });
  }

  dismiss(trigger) {
    window.webviewEventsListeners.backPress.pop();
    super.dismiss(trigger);
  }

  renderModal() {
    const originalIds = this.props.duplicates
      .filter((v,i,a) => a.findIndex((v2) => (v2.hash === v.hash)) === i)
      .map((v) => v.id);

    return <React.Fragment>
      <div className="image-duplicate-container">
        {this.props.duplicates.map((image) => <div
          key={image.id}
          className={originalIds.includes(image.id) ? "image-item--original" : "image-item--duplicate"}>
            <img src={image.url} alt=""/>
          </div>
        )}
      </div>

      <p
        className="image-duplicate-text"
        dangerouslySetInnerHTML={{__html: i18n.t("create_page__duplicate_photos", {n: 10, m: 20})}}
      />

      <button
        className="btn btn-white"
        children={i18n.t("button__ok")}
        onClick={() => this.dismiss()} />
    </React.Fragment>;
  }
}
