import React from "react";
import PropTypes from "prop-types";

export default class CountdownTimer extends React.Component {

  state = {
    minutes: 0,
    seconds: 0,
  };

  componentDidMount() {
    this.tick();
    this.timerId = setInterval(this.tick, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  getDiff = () => {
    const leftSeconds = Math.ceil((this.props.targetMillis - Date.now()) / 1000);

    const diff = {
      minutes: 0,
      seconds: 0,
    };

    if (leftSeconds > 0) {
      diff.minutes = Math.floor(((leftSeconds % 86_400) % 3_600) / 60);
      diff.seconds = Math.floor(leftSeconds % 60);
    }

    return diff;
  };

  tick = () => {
    const diff = this.getDiff();
    const nextState = {...diff};

    this.setState(nextState, () => {
      const countDown = Object.values(diff).reduce((a, b) => a + b);

      if (countDown === 0) {
        clearInterval(this.timerId);
        this.props.onExpire && this.props.onExpire();
      }
    });
  };

  render() {
    const minutes = pad(this.state.minutes);
    const seconds = pad(this.state.seconds);

    return <div className="countdown-timer">
      <span>{minutes}</span>
      :
      <span>{seconds}</span>
    </div>;
  }
}

CountdownTimer.propTypes = {
  targetMillis: PropTypes.number.isRequired,
};

function pad(value) {
  value = ("0" + value);

  return value.substring(value.length - 2);
}
