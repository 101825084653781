import React from "react";
import Modal from "../../components/Modal";
import {Loader} from "../../components/Loader";
import {webviewOpenBrowser} from "../../utils/webview";
import i18n from "../../i18n";
import neuroavatarsHelper from "../../helpers/neuroavatars.helper";
import PropTypes from "prop-types";
import webviewHelper from "../../helpers/webview.helper";

class WalletconnectApiError extends Error {
  name = "WalletconnectApiError";
  constructor(message, code) {
    super(message);
    this.code = code;
  }
}

function extractAddressFromAppResponse(response) {
  const item = response.eip155?.accounts?.find((item) => {
    return item.indexOf("eip155:1:") === 0;
  });

  if (item) {
    return item.split(":")[2];
  }

  return null;
}

export default class TooncoinConnectModal extends Modal {

  state = {
    isLoading: true,
    error: null,
    address: null,
  };

  componentDidMount() {
    this.start();
  }

  start = async () => {
    const nextState = {};

    try {
      const res = await webviewHelper.walletconnectGetState();
      const address = extractAddressFromAppResponse(res);

      if (address) {
        if (this.props.isAutoStart) {
          const signature = await this.sign(address);
          this.dismiss();
          this.props.onSuccess(address, signature);
        } else {
          nextState.isLoading = false;
          nextState.address = address;
        }
      } else {
        nextState.isLoading = false;
      }
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState(nextState);
    }
  };

  handleConnectNewAddressClick = async () => {
    this.setState({address: null});

    try {
      await webviewHelper.walletconnectReset();
      const res = await webviewHelper.walletconnectAuth();

      if (res.error) {
        throw new WalletconnectApiError(res.error.message, res.error.code);
      }

      const targetChainAccount = res.eip155?.accounts?.find((item) => {
        return item.indexOf("eip155:1:") === 0;
      });

      if (!targetChainAccount) {
        throw new WalletconnectApiError(i18n.t("error__switch_to_mainnet_eth"));
      }

      const address = extractAddressFromAppResponse(res);
      if (address) {
        this.setState({isLoading: true});
        await this.checkAddress(address);
        this.setState({isLoading: false, address});
        this.dismiss();
        this.props.onSuccess(address, null);
      } else {
        throw new Error("No ethereum mainnet");
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  handleUseLatestAddressClick = async (address) => {
    try {
      await this.checkAddress(address);
      this.dismiss();
      this.props.onSuccess(address, null);
    } catch (err) {
      this.handleError(err);
    }
  };

  handleNotNowButtonClick = () => {
    this.dismiss();
  };

  checkAddress = async (address) => {
    const res = await neuroavatarsHelper.packsCheckAddress(address);
    if (res.ok) {
      return res;
    } else {
      if (res.message.toLowerCase() === "limit reached") {
        throw new LimitReachedError();
      } else {
        throw new EmptyBalanceError();
      }
    }
  };

  sign = async (address) => {
    const token = window.clientConfig.webviewParams["aid"]
      || window.clientConfig.webviewParams["vicman_unified_id"];
    const res = await webviewHelper.walletconnectSign(`eip155:1:${address}`, btoa(token));

    if (res.error) {
      throw new WalletconnectApiError(res.error.message, res.error.code);
    } else if (res.signature) {
      return res.signature;
    } else {
      throw new WalletconnectApiError("No signature", "no_signature");
    }
  };

  handleError = (err) => {
    console.error(err);

    this.setState({
      isLoading: false,
      error: err,
    });
  };

  handleTryAnotherWallet = async () => {
    this.setState({
      error: null,
      address: null,
    });
  };

  handleBuyToonCoinButtonClick = () => {
    webviewOpenBrowser(
      "https://toondao.com",
      window.clientConfig.isWebviewIOS ? "safari" : undefined
    );
  };

  renderModal() {
    if (this.state.isLoading) {
      return <React.Fragment>
        <Loader className="relative" />
      </React.Fragment>;
    }

    if (this.state.error) {
      return this.renderError();
    }

    return <React.Fragment>
      <p dangerouslySetInnerHTML={{__html: i18n.t("tooncoin_connect__connect_text")}}/>

      {this.state.address && <button
        className="btn btn-white"
        onClick={() => this.handleUseLatestAddressClick(this.state.address)}
        dangerouslySetInnerHTML={{__html: i18n.t("tooncoin_connect__connect_latest_button", {address: truncateAddress(this.state.address)})}}
      />}

      <button
        className="btn btn-white mt-16"
        onClick={() => this.handleConnectNewAddressClick()}>
        {i18n.t(!!this.state.address
          ? "tooncoin_connect__connect_new_button"
          : "tooncoin_connect__connect_button")}
      </button>
      <button className="btn btn-transparent mt-16" onClick={this.handleNotNowButtonClick}>
        {i18n.t("tooncoin_connect__connect_notnow_button")}
      </button>
    </React.Fragment>;
  }

  renderError = () => {
    if (this.state.error.name === "LimitReachedError") {
      const hasPackByTokens = this.context.packs.length > 1;
      const messageText = hasPackByTokens
        ? i18n.t("tooncoin_connect__limit_reached_10")
        : i18n.t("tooncoin_connect__limit_reached_1");

      return <React.Fragment>
        <p dangerouslySetInnerHTML={{__html: messageText}} />
        {/* <button
          hidden={hasPackByTokens}
          className="btn btn-white"
          onClick={this.handleBuyToonCoinButtonClick}>
          {i18n.t("tooncoin_connect__buy_tooncoin_button")}
        </button> */}
        <button
          className="btn btn-transparent mt-16"
          onClick={() => this.dismiss()}>
          {i18n.t("tooncoin_connect__close_button")}
        </button>
      </React.Fragment>;
    }

    if (this.state.error.name === "EmptyBalanceError") {
      return <React.Fragment>
        <p dangerouslySetInnerHTML={{__html: i18n.t("tooncoin_connect__empty_balance", {address: this.state.address})}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("tooncoin_connect__try_another")}} />
        {/* <button
          className="btn btn-white"
          onClick={this.handleBuyToonCoinButtonClick}>
          {i18n.t("tooncoin_connect__buy_tooncoin_button")}
        </button> */}
        <button
          className="btn btn-white mt-16"
          onClick={this.handleTryAnotherWallet}>
          {i18n.t("tooncoin_connect__use_another_wallet")}
        </button>
        <button
          className="btn btn-transparent mt-16"
          onClick={() => this.dismiss()}>
          {i18n.t("tooncoin_connect__close_button")}
        </button>
      </React.Fragment>;
    }

    let message = i18n.t("error__default_message");
    if (this.state.error.name === "WalletconnectApiError") {
      message = this.state.error.message;
    }

    return <React.Fragment>
      <p dangerouslySetInnerHTML={{__html: message}} />
      <button
        className="btn btn-white"
        onClick={() => this.dismiss()}>
        {i18n.t("button__ok")}
      </button>
    </React.Fragment>;
  };
}

TooncoinConnectModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  isAutoStart: PropTypes.bool,
};

class LimitReachedError extends Error {
  constructor(message) {
    super(message);
    this.name = "LimitReachedError";
  }
}

class EmptyBalanceError extends Error {
  constructor(message) {
    super(message);
    this.name = "EmptyBalanceError";
  }
}

function truncateAddress(address) {
  return address.substring(0, 9) + "…" + address.substring(34);
}