import React from "react";
import Modal from "../../components/Modal";
import i18n from "../../i18n";
import {hitEvent, hits, logEvent, userEvents} from "../../utils/log";
import {isWebviewApp} from "../../utils/config.utils";
import {webviewAppIds, webviewOpenBrowser} from "../../utils/webview";

const stores = {
  newprofilepic: {
    ios: "https://apps.apple.com/app/id1605986793",
    android: "https://play.google.com/store/apps/details?id=com.vicman.newprofilepic",
  },
  toonme: {
    ios: "https://apps.apple.com/us/app/toonme-cartoon-photo-editor/id1508120751",
    android: "https://play.google.com/store/apps/details?id=com.vicman.toonmeapp",
  },
};

export default class UpdateAppModal extends Modal {

  componentDidMount() {
    logEvent(userEvents.UPDATE_APP_MODAL_SHOWN);
    hitEvent(hits.updateAppModalShown);

    window.webviewEventsListeners.backPress.push(() => {
      this.dismiss();
      return true;
    });
  }

  dismiss(trigger) {
    window.webviewEventsListeners.backPress.pop();
    super.dismiss(trigger);
  }

  handleConfirmButtonClick = () => {
    logEvent(userEvents.UPDATE_APP_MODAL_PRESS_CONFIRM);
    hitEvent(hits.updateAppModalPressConfirm);

    let links;

    if (isWebviewApp(webviewAppIds.toonme)) {
      links = stores.toonme;
    } else if (isWebviewApp(webviewAppIds.newprofilepic)) {
      links = stores.newprofilepic;
    } else {
      console.warn("No links for current app_id");
      return;
    }

    webviewOpenBrowser(
      window.clientConfig.isWebviewIOS ? links.ios : links.android,
      window.clientConfig.isWebviewIOS ? "safari" : undefined
    );
  };

  renderModal() {
    return <React.Fragment>
      <p dangerouslySetInnerHTML={{__html: i18n.t("update_app_modal_message")}} />
      <button
        className="btn btn-white"
        onClick={this.handleConfirmButtonClick}>
        {i18n.t("update_app_modal_button_confirm")}
      </button>

      <button
        className="btn btn-transparent mt-24"
        onClick={() => this.dismiss()}>
        {i18n.t("update_app_modal_button_cancel")}
      </button>
    </React.Fragment>;
  }
}
