import {createMd5Token} from "../utils/text";

export default class Creative {

  static STATUS_FAILED = -1;
  static STATUS_PENDING = 0;
  static STATUS_PROCESSED = 1;

  static EXTRA_STARTED_AT = "started_at";
  static EXTRA_FINISHED_AT = "finished_at";
  static EXTRA_ALIAS = "alias";
  static EXTRA_COMBO_STEPS = "combo_steps";
  static EXTRA_SELECTED_AT = "selected_at";
  static EXTRA_PROCESSING_TIMEOUT = "processing_timeout";
  static EXTRA_WM_ON_DEMAND = "wm_on_demand";
  static EXTRA_ON_GENDER = "on_gender";
  static EXTRA_FIRST_DOWNLOAD_AT = "first_download_at";

  // вьюшка с этотй обработкой (не картинкой) хоть раз была отрендерена юзеру
  static EXTRA_VIEW_OPENED = "view_opened";

  // превьюшка обработки
  static EXTRA_PREVIEW_URL = "preview_url";
  static EXTRA_PREVIEW_URL_FIXED = "preview_url_fixed";

  // пока это поле true, процессинг не будет его стартовать
  static EXTRA_KEEP_PENDING = "keep_pending";

  static EXTRA_VISIBLE_AFTER_AT = "visible_after_at";
  static EXTRA_HIDDEN_AFTER_AT = "hidden_after_at";

  // в минутах
  static EXTRA_STATUS_NEW_DURATION = "status_new_duration";

  // принудительно поставить значок "new"
  static EXTRA_FORCE_IS_NEW = "force_is_new";

  static EXTRA_POSITION = "position";

  static EXTRA_NAME = "name";

  _data = {};

  constructor() {
    this._data.fileId = 0;
    this._data.processingId = 0;
    this._data.status = Creative.STATUS_PENDING;
    this._data.group = "";
    this._data.alias = "";
    this._data.templateId = 0;
    this._data.handler = "";
    this._data.isSelected = false;
    this._data.isRefreshed = false;
    this._data.tasks = {};
    this._data.tasksConfigs = {};
    this._data.files = {};
    this._data.error = null;
    this._data.extra = {};
    this._data.result = null;
  }

  fromObject(obj) {
    this._data = {};

    Object.keys(obj).forEach((key) => {
      this._data[key] = obj[key];
    });

    return this;
  }

  toPlainObject() {
    return JSON.parse(JSON.stringify(this._data));
  }

  copy() {
    const copiedData = this.toPlainObject();
    copiedData.id = createMd5Token(
      Date.now(),
      this._data.group,
      this._data.templateId,
      this._data.handler
    );

    const copying = new Creative();
    copying.fromObject(copiedData);

    return copying;
  }

  /** @param {CreativeConfig} config */
  configureByConfig(config) {
    this._data.id = createMd5Token(
      Date.now(),
      config.group,
      config.templateId,
      config.handler
    );

    this._data.group = config.group;
    this._data.templateId = config.templateId;
    this._data.handler = config.handler;

    Object.keys(config.extra).forEach((key) => {
      this.setExtra(key, config.extra[key]);
    });

    return this;
  }

  setProcessingId(id) {
    this._data.processingId = id;
    return this;
  }

  setFileId(fileId) {
    this._data.fileId = fileId;
    return this;
  }

  setTemplateId(templateId) {
    this._data.templateId = templateId;
    return this;
  }

  setAlias(alias) {
    this._data.alias = alias;
    return this;
  }

  setAsSelected(flag) {
    this._data.isSelected = !!flag;
    return this;
  }

  setAsRefreshed(flag) {
    this._data.isRefreshed = !!flag;
    return this;
  }

  markAsPending() {
    this._data.status = Creative.STATUS_PENDING;

    return this;
  }

  markAsProcessed(result) {
    this._data.result = result;
    this._data.status = Creative.STATUS_PROCESSED;
    this.setExtra(Creative.EXTRA_FINISHED_AT, Date.now());

    return this;
  }

  markAsFailed(error) {
    this._data.error = error;
    this._data.status = Creative.STATUS_FAILED;
    this.setExtra(Creative.EXTRA_FINISHED_AT, Date.now());

    return this;
  }

  getTasks() {
    return this._data.tasks;
  }

  findTasks(pattern) {
    const tasksNames = Object.keys(this._data.tasks);
    const results = {};
    tasksNames.forEach((taskName) => {
      if (pattern.test(taskName)) {
        results[taskName] = this.getTask(taskName);
      }
    })

    return results;
  }

  getTask(name) {
    return this._data.tasks[name] || null;
  }

  setTask(name, task) {
    this._data.tasks[name] = task;
    return this;
  }

  setTaskConfig(name, config) {
    this._data.tasksConfigs[name] = config;
    return this;
  }

  getTaskConfig(name) {
    return this._data.tasksConfigs[name] || null;
  }

  getFile(key) {
    return this._data.files[key] || null;
  }

  setFile(key, url) {
    this._data.files[key] = url;
    return this;
  }

  get id() {
    return this._data.id;
  }

  get fileId() {
    return this._data.fileId;
  }

  get processingId() {
    return this._data.processingId;
  }

  get group() {
    return this._data.group;
  }

  get templateId() {
    return this._data.templateId;
  }

  get handler() {
    return this._data.handler;
  }

  get isSelected() {
    return this._data.isSelected;
  }

  get isRefreshed() {
    return this._data.isRefreshed;
  }

  get isProcessed() {
    return this._data.status === Creative.STATUS_PROCESSED;
  }

  get isFinished() {
    return this._data.status === Creative.STATUS_PROCESSED
        || this._data.status === Creative.STATUS_FAILED;
  }

  get isFailed() {
    return this._data.status === Creative.STATUS_FAILED;
  }

  get isPending() {
    return this._data.status === Creative.STATUS_PENDING;
  }

  get result() {
    return this._data.result;
  }
  
  get error() {
    return this._data.error;
  }

  get alias() {
    return this._data.alias;
  }

  get data() {
    return this._data;
  }

  inGroup(group) {
    return this._data.group === group;
  }

  inGroups(groups) {
    return groups.indexOf(this._data.group) > -1;
  }

  setExtra(key, value) {
    this._data.extra[key] = value;
    return this;
  }

  hasExtra(key) {
    return this._data.extra.hasOwnProperty(key);
  }

  getExtra(key, defaultValue) {
    return this.hasExtra(key)
      ? this._data.extra[key]
      : defaultValue;
  }

  removeExtra(key) {
    delete this._data.extra[key];
  }
}