/* eslint-disable no-unused-vars */

import axios from "axios";
import axiosRetry from "axios-retry";
import PhotolabTaskBuilder from "./PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "./PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "./PhotolabTaskCollageMethod";
import promiseRetry from "promise-retry";

const xmlParser = new DOMParser();
const httpClient = axios.create({
  baseURL: window.appConfig.photolab.path,
});

axiosRetry(httpClient, {
  retries: 3,
  retryDelay: 1000,
});

export class PhotolabResponseError extends Error {
  constructor(code, message, requestId = undefined) {
    super(message);
    this.name = "PhotolabResponseError";
    this.requestId = requestId;
    this.code = code;
  }
}

export class PhotolabResponseParseError extends Error {
  constructor(xmlString) {
    super(`Error XML response parsing`);
    this.name = "PhotolabResponseParseError";
    this.code = -1;
    this.xmlString = xmlString;
  }
}

function checkError(err) {
  throw err;
}

function getResultTask(requestId) {
  function requestFunc(retry) {
    return httpClient.post("/getresult?request_id=" + requestId + "&r=" + Math.random())
      .catch(retry);
  }

  return promiseRetry(requestFunc, {retries: 5, minTimeout: 1000, maxTimeout: 1000})
    .then((res) => parseTaskResult(res.data))
    .catch(checkError);
}

export function photolabSimpleTask(templateId, imageObjectOrUrl, timeout = 1000, interval = 500) {
  const taskConfig = new PhotolabTaskBuilder();
  taskConfig.addMethod(new PhotolabTaskCollageMethod(templateId));
  taskConfig.addImage(new PhotolabTaskImageUrl(imageObjectOrUrl));
  taskConfig.setLanguage(window.clientConfig.lang);

  return photolabAddTask(taskConfig.buildToJs())
    .then((taskResult) => photolabWaitTask(taskResult.requestId, timeout, interval));
}

export function photolabGenderTask(imageObjectOrUrl, timeout = 1000, interval = 500) {
  return photolabSimpleTask("gender_classifier", imageObjectOrUrl, timeout, interval);
}

export function photolabTask(taskConfig, optionsArg) {
  const options = Object.assign({
    timeout: 1000,
    interval: 1000,
  }, optionsArg);

  return photolabAddTask(taskConfig).then((taskResult) => {
    return photolabWaitTask(taskResult.requestId, options.timeout, options.interval);
  });
}

export function photolabAddTask(taskJsConfig) {
  const params = {
    // i_am_debugger: true,
    app_id: window.appConfig.photolab.appId,
    client_token: window.clientConfig.token,
    client_build: window.appConfig.build.version,
    project_name: window.appConfig.project.name,
    task: taskJsConfig,
  };

  const requestFunc = (retry) => {
    return httpClient.post(window.appConfig.photolab.addTaskEndpoint, params)
      .then((res) => res.data.task)
      .catch(retry);
  };

  return promiseRetry(requestFunc, {
    retries: 3,
    minTimeout: 1000,
    maxTimeout: 1000,
  });
}

export function photolabWaitTask(requestId, timeout = 0, interval = 1000) {
  function getResultFunc(resolve, reject) {
    getResultTask(requestId)
      .then((taskResult) => {
        if (taskResult.status === "OK") {
          if (taskResult.resultUrl) {
            taskResult.resultUrl = taskResult.resultUrl.replace("http://", "https://")
          }

          resolve(taskResult);
        } else {
          photolabWaitTask(requestId, interval, interval).then(resolve).catch(reject);
        }
      })
      .catch(reject);
  }

  return new Promise((resolve, reject) => {
    if (timeout <= 0) {
      getResultFunc(resolve, reject);
    } else {
      setTimeout(() => getResultFunc(resolve, reject), timeout);
    }
  });
}

function parseTaskResult(xmlString, requestId) {
  const xmldoc = xmlParser.parseFromString(xmlString, "application/xml");

  if (xmldoc.documentElement.nodeName === "parsererror") {
    throw new PhotolabResponseParseError(xmlString);
  }

  if (xmldoc.documentElement.querySelector("err_code") !== null) {
    throw new PhotolabResponseError(
      parseInt(xmldoc.documentElement.querySelector("err_code").textContent),
      xmldoc.documentElement.querySelector("description").textContent,
      requestId
    );
  }

  const status = xmldoc.documentElement.querySelector("status").textContent;
  const response = {
    requestId: xmldoc.documentElement.querySelector("request_id").textContent,
    status: status,
  };

  if (status === "OK") {
    const resultUrlNode = xmldoc.documentElement.querySelector("result_url");
    if (resultUrlNode) {
      response.resultUrl = resultUrlNode.textContent;
    }

    response.duration = xmldoc.documentElement.querySelector("duration").textContent;
    response.totalDuration = xmldoc.documentElement.querySelector("total_duration").textContent;

    const genderNode = xmldoc.documentElement.querySelector("gender");
    if (genderNode) {
      response.gender = {
        value: genderNode.querySelector("value").textContent,
        probability: parseFloat(genderNode.querySelector("probability").textContent),
      };
    }

    const resultsNode = xmldoc.documentElement.querySelector("results");
    if (resultsNode) {
      response.results = [];

      resultsNode.childNodes.forEach((resultNode) => {
        response.results.push({
          templateId: parseInt(resultNode.getAttribute("template_name")),
          resultUrl: resultNode.textContent,
        });
      });
    }

    const humansNode = xmldoc.documentElement.querySelector("humans");
    if (humansNode) {
      response.humans = [];
      response.originalSize = {width: 0, height: 0};

      humansNode.childNodes.forEach((humansChildNode) => {
        if (humansChildNode.nodeName === "human") {
          const humanItem = {
            skeletonPoints: [],
            bbox: {
              minPoint: {x: 0, y: 0},
              maxPoint: {x: 0, y: 0},
              color: {r: 0, g: 0, b: 0, a: 0},
            },
          };

          humansChildNode.childNodes.forEach((humanChildNode) => {
            if (humanChildNode.nodeName === "skeleton_points") {
              humanChildNode.querySelectorAll("point").forEach((pointNode) => {
                const point = {x: 0, y: 0, groupName: "", id: 0};
                point.id = parseInt(pointNode.getAttribute("id"));
                point.groupName = pointNode.getAttribute("group_name");
                point.x = parseFloat(pointNode.querySelector("x").textContent);
                point.y = parseFloat(pointNode.querySelector("y").textContent);
                point.p = parseFloat(pointNode.querySelector("p").textContent);
                humanItem.skeletonPoints.push(point);
              });
            } else if (humanChildNode.nodeName === "bbox") {
              humanItem.bbox.minPoint.x = parseFloat(humanChildNode.querySelector("min_point > x").textContent);
              humanItem.bbox.minPoint.y = parseFloat(humanChildNode.querySelector("min_point > y").textContent);
              humanItem.bbox.maxPoint.x = parseFloat(humanChildNode.querySelector("max_point > x").textContent);
              humanItem.bbox.maxPoint.y = parseFloat(humanChildNode.querySelector("max_point > y").textContent);
              humanItem.bbox.color.r = parseFloat(humanChildNode.querySelector("Color > R").textContent);
              humanItem.bbox.color.g = parseFloat(humanChildNode.querySelector("Color > G").textContent);
              humanItem.bbox.color.b = parseFloat(humanChildNode.querySelector("Color > B").textContent);
              humanItem.bbox.color.a = parseFloat(humanChildNode.querySelector("Color > A").textContent);
            }
          });

          response.humans.push(humanItem);
        } else if (humansChildNode.nodeName === "original_size") {
          response.originalSize.width = parseInt(humansChildNode.querySelector("width").textContent);
          response.originalSize.height = parseInt(humansChildNode.querySelector("height").textContent);
        }
      });
    }
  }

  return response;
}