import React from "react";
import AppContext from "../../contexts/AppContext";
import neuroApiHelper from "../../helpers/neuro-api.helper";
import * as webviewUtils from "../../utils/webview";
import {shareProvidersIds, webviewAppIds} from "../../utils/webview";
import i18n from "../../i18n";
import routes from "../../routes";
import ResultImageView from "./ResultImageView";
import ProcessingView from "./ProccessingView";
import VisibilityWatcher from "../../components/VisibilityWatcher";
import clientStorage from "../../utils/client-storage";
import {hitEvent, hitEventOnce, hits, logEvent, logProcessingsTimings, userEvents} from "../../utils/log";
import WatermarkBuilderModal from "../shared/watermark-builder/WatermarkBuilderModal";
import {isWebviewApp} from "../../utils/config.utils";
import {webviewAnalyticsEventIfTooncoin} from "../../utils/tooncoin-log";
import {runOnceByLocalStorage} from "../../utils/etc";
import {signalEvent, signals} from "../../utils/signals";

const timerInterval = 60_000;
const viewMode = {
  grid: "grid",
  list: "list",
};
const accessMode = {
  trial: "trial",
  full: "full",
  free: "free",
};

export default class PackPage extends React.Component {

  state = {
    pack: null,
    viewMode: viewMode.grid,
    accessMode: accessMode.free,
  };

  constructor(props) {
    super(props);

    if (window.clientConfig.isTrial) {
      this.state.accessMode = accessMode.trial;
    } else if (window.clientConfig.isPro) {
      this.state.accessMode = accessMode.full;
    } else {
      this.state.accessMode = accessMode.free;
    }
  }

  componentDidMount() {
    hitEvent(hits.packVisit);
    logEvent(userEvents.PACK_VISIT, {
      pack_id: this.props.match.params.id,
    });

    window.webviewEventsListeners.backPress.push(() => {
      this.props.history.replace(routes.INDEX);
      return true;
    });

    const pack = this.context.packs
      .find((p) => p.id === this.props.match.params.id);

    if (pack) {
      this.setState({pack}, this.context.hideLoader);
    } else {
      this.context.showLoader();
    }

    this.fetchPack();
  }

  componentWillUnmount() {
    window.webviewEventsListeners.backPress.pop();
    clearTimeout(this.timer);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.pack != null && this.state.pack.status === neuroApiHelper.packStatus.ready) {
      hitEventOnce(hits.packResultOnce);
      webviewAnalyticsEventIfTooncoin("pack_shown", "", "pack");
    }

    if (this.state.pack != null && prevState.pack === null) {
      if (this.state.pack.status === neuroApiHelper.packStatus.pending) {
        hitEvent(hits.packProcessingShown);
      }
    }
  }

  onResume = () => {
    this.fetchPack();
  };

  onPause = () => {
    clearTimeout(this.timer);
  };

  fetchPack = () => {
    neuroApiHelper.fetchPack(this.props.match.params.id).then((pack) => {
      this.setState({pack}, () => {
        if (this.state.pack.status === neuroApiHelper.packStatus.ready) {
          if (window.clientConfig.isWebview) {
            webviewUtils.webviewCancelCheck(this.state.pack.id);
          }

          this.logProcessingTime();
        } else {
          this.timer = setTimeout(this.fetchPack, timerInterval);
        }
        this.context.hideLoader();
      });
    }).catch((err) => {
      this.timer = setTimeout(this.fetchPack, timerInterval);
      this.context.hideLoader();

      console.error(err);
    });
  };

  handleDownload = (imageUrl) => {
    const pack = this.state.pack;

    function startDownload(downloadImageUrl, withWatermark) {
      hitEvent(hits.download);
      hitEventOnce(hits.downloadOnce);
      logEvent(userEvents.DOWNLOAD, {
        pack_id: pack.id,
        position_in_pack: pack.results.indexOf(imageUrl),
        with_watermark: !!withWatermark,
      });

      if (!clientStorage.getFirstDownloadByPack(pack.id)) {
        hitEvent(hits.firstDownloadByPack);
        clientStorage.setFirstDownloadByPack(pack.id);
      }

      webviewUtils.webviewShare({
        providers: "[" + shareProvidersIds.save + "]",
        imageUrl: encodeURIComponent(downloadImageUrl),
        hashtag: encodeURIComponent("#AIAvatars"),
        autosave: 1,
      });
    }

    if (window.clientConfig.features.downloadWithWatermarkBuilder) {
      this.context.pushModal(<WatermarkBuilderModal
        key="PackPage_WatermarkBuilderModal"
        imageUrl={imageUrl}
        onDownload={startDownload}
      />);
    } else {
      startDownload(imageUrl);
    }

    runOnceByLocalStorage("signal_download_is_sent_" + this.state.pack.id, () => {
      signalEvent(signals.download);
    });
  };

  handleSelect = (imageUrl) => {
    this.handleDownload(imageUrl);
    // let doDownload = false;
    //
    // this.context.pushModal(<ImageModal
    //   key="PackPage_ImageModal"
    //   imageUrl={imageUrl}
    //   onDownloadClick={() => doDownload = true}
    //   onDismissed={() => {
    //     if (doDownload) {
    //       this.handleDownload(imageUrl);
    //     }
    //   }}
    // />);
  };

  logProcessingTime = () => {
    if (!clientStorage.getPackProcessingTimeIsLogged(this.state.pack.id)) {
      logProcessingsTimings(this.state.pack.duration);
      clientStorage.setPackProcessingTimeIsLogged(this.state.pack.id);
    }
  };

  handleGoToIndexPage = () => {
    this.props.history.push(routes.INDEX);
  };

  handleGoToPacksPage = () => {
    this.props.history.push(routes.PACKS);
  };

  handleSubscribeButtonClick = () => {
    // if (window.clientConfig.isBenefitCountry) {
    //   webviewUtils.webviewShowBanner("pro_web");
    // } else {
    //   webviewUtils.webviewShowBanner("avatars_indo");
    // }

    webviewUtils.webviewShowBanner("avatars_indo");
  };

  handleSubscriptionUpgradeButtonClick = () => {
    let sku = "";

    if (isWebviewApp(webviewAppIds.toonme)) {
      if (window.clientConfig.isWebviewAndroid) {
        sku = "week_2_nt";
      } else {
        sku = "pro_features_tnmweek_4_nt";
      }
    } else if (isWebviewApp(webviewAppIds.newprofilepic)) {
      if (window.clientConfig.isWebviewAndroid) {
        sku = "week_2_nt";
      } else {
        sku = "weekly_sub_4_nt";
      }
    } else {
      console.warn("No SKU for current app_id");
      return;
    }

    webviewUtils.webviewInApp(sku);
  };

  renderProImagesMessage = () => {
    const num = window.appConfig.proResultsCount;

    switch (this.state.accessMode) {
      case accessMode.free: {
        return <div className={`image-messages ${this.state.viewMode === viewMode.list ? "image-messages-1" : "image-messages-2"}`}>
          <h3 dangerouslySetInnerHTML={{__html: i18n.t("pack_more_images_free_title", {num})}} />
          <p dangerouslySetInnerHTML={{__html: i18n.t("pack_more_images_free_text")}} />
          <button className="btn btn-white" onClick={this.handleSubscribeButtonClick}>
            {i18n.t("pack_more_images_free_button")}
          </button>
        </div>;
      }
      case accessMode.trial: {
        return <div className={`image-messages ${this.state.viewMode === viewMode.list ? "image-messages-1" : "image-messages-2"}`}>
          <h3 dangerouslySetInnerHTML={{__html: i18n.t("pack_more_images_trial_title", {num})}} />
          <p dangerouslySetInnerHTML={{__html: i18n.t("pack_more_images_trial_text")}} />
          <button className="btn btn-white" onClick={this.handleSubscriptionUpgradeButtonClick}>
            {i18n.t("pack_more_images_trial_button")}
          </button>
        </div>;
      }
      case accessMode.full: {
        return <div className="image-messages">
          <p className="mb-0" dangerouslySetInnerHTML={{__html: i18n.t("pack_more_images_pro", {num})}} />
        </div>;
      }
      default: {
        return <React.Fragment />;
      }
    }
  };

  handleGoToAnotherStaffClick = () => {
    if (isWebviewApp(webviewAppIds.toonme)) {
      window.location.href = "toonme://navigate/tab?id=1000";
    } else if (isWebviewApp(webviewAppIds.newprofilepic)) {
      window.location.href = "newprofilepic://navigate/tab?id=1300";
    } else if (isWebviewApp(webviewAppIds.tooncoin)) {
      window.location.href = "tooncoin://navigate/tab?id=1003";
    } else {
      // nothing
    }
  };

  render() {
    const isPending = this.state.pack && this.state.pack.status === neuroApiHelper.packStatus.pending;

    if (this.state.pack === null || isPending) {
      return <React.Fragment>
        <VisibilityWatcher
          onResume={this.onResume}
          onPause={this.onPause} />
        {isPending && <ProcessingView
          pack={this.state.pack}
          canBack={true}
          onGoToAnotherStaffClick={this.handleGoToAnotherStaffClick}
          onBackClick={this.handleGoToPacksPage}
          onGoToPacksPage={this.handleGoToPacksPage} />}
      </React.Fragment>;
    }

    // const freeImages = this.state.pack.results.slice(0, -window.appConfig.proResultsCount);
    // const proImages = this.state.pack.results.slice(-window.appConfig.proResultsCount);

    return <div className="container">
      <header>
        <button onClick={this.handleGoToIndexPage}>
          <svg viewBox="0 0 24 24"><path d="m5.7 10.5 8.4-8.4L12 0 0 12l12 12 2.1-2.1-8.4-8.4H24v-3z" fill="#fff" fillRule="evenodd"/></svg>
        </button>
        <h1>{i18n.t("pack_page__title")}</h1>
      </header>
      <div>
        <div className="btns-view-container">
          <button
            className={"btn-check-view" + (this.state.viewMode === viewMode.grid ? " active" : "")}
            onClick={() => {this.setState({viewMode: viewMode.grid})}}>
            <svg viewBox="0 0 16 16">
              <rect width="7" height="7" rx="1"/>
              <rect x="9" width="7" height="7" rx="1"/>
              <rect y="9" width="7" height="7" rx="1"/>
              <rect x="9" y="9" width="7" height="7" rx="1"/>
            </svg>
          </button>
          <button
            className={"btn-check-view" + (this.state.viewMode === viewMode.list ? " active" : "")}
            onClick={() => {this.setState({viewMode: viewMode.list})}}>
            <svg viewBox="0 0 12 12">
              <rect width="12" height="12" rx="2"/>
            </svg>
          </button>
        </div>

        <div className={`grid-cols-${this.state.viewMode === viewMode.list ? "1" : "2"}`}>
          {this.state.pack.results.map((imageUrl) => <ResultImageView
            key={imageUrl}
            imageUrl={imageUrl}
            onDownload={this.handleDownload}
            onSelect={this.handleSelect}
          />)}
        </div>

        {/*<div className={`grid-cols-${this.state.viewMode === viewMode.list ? "1" : "2"}`}>*/}
        {/*  {freeImages.map((imageUrl) => <ResultImageView*/}
        {/*    key={imageUrl}*/}
        {/*    imageUrl={imageUrl}*/}
        {/*    onDownload={this.handleDownload}*/}
        {/*    onSelect={this.handleSelect}*/}
        {/*  />)}*/}
        {/*</div>*/}

        {/*{this.renderProImagesMessage()}*/}

        {/*<div*/}
        {/*  className={`grid-cols-${this.state.viewMode === viewMode.list ? "1" : "2"}`}*/}
        {/*  hidden={this.state.accessMode !== accessMode.full}>*/}
        {/*  {proImages.map((imageUrl) => <ResultImageView*/}
        {/*    key={imageUrl}*/}
        {/*    imageUrl={imageUrl}*/}
        {/*    onDownload={this.handleDownload}*/}
        {/*    onSelect={this.handleSelect}*/}
        {/*  />)}*/}
        {/*</div>*/}

        <button
          className="btn btn-violet btn-fixed-bottom"
          hidden={true}
          onClick={() => {}}>
          {i18n.t("button__download_all")}
        </button>
      </div>
    </div>;
  }
}

PackPage.contextType = AppContext;