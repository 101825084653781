import {assetUrl} from "../../utils/etc";
import React from "react";

export default function PuzzleContainer() {
  return <div className="puzzles-container">
    <div className="puzzles-row puzzles-row-1">
      <div className="puzzle-item">
        <img src={assetUrl("assets/images/puzzle-1-3.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-1-2.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-1-1.jpeg")} alt="" />
      </div>
      <div className="puzzle-item">
        <img src={assetUrl("assets/images/puzzle-2-3.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-2-2.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-2-1.jpeg")} alt="" />
      </div>
      <div className="puzzle-item">
        <img src={assetUrl("assets/images/puzzle-3-3.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-3-2.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-3-1.jpeg")} alt="" />
      </div>
    </div>
    <div className="puzzles-row puzzles-row-2">
      <div className="puzzle-item">
        <img src={assetUrl("assets/images/puzzle-4-3.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-4-2.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-4-1.jpeg")} alt="" />
      </div>
      <div className="puzzle-item">
        <img src={assetUrl("assets/images/puzzle-5-3.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-5-2.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-5-1.jpeg")} alt="" />
      </div>
      <div className="puzzle-item">
        <img src={assetUrl("assets/images/puzzle-6-3.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-6-2.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-6-1.jpeg")} alt="" />
      </div>
    </div>
    <div className="puzzles-row puzzles-row-3">
      <div className="puzzle-item">
        <img src={assetUrl("assets/images/puzzle-7-3.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-7-2.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-7-1.jpeg")} alt="" />
      </div>
      <div className="puzzle-item">
        <img src={assetUrl("assets/images/puzzle-8-3.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-8-2.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-8-1.jpeg")} alt="" />
      </div>
      <div className="puzzle-item">
        <img src={assetUrl("assets/images/puzzle-9-3.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-9-2.jpeg")} alt="" />
        <img src={assetUrl("assets/images/puzzle-9-1.jpeg")} alt="" />
      </div>
    </div>
  </div>
}