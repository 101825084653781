import React from "react";
import Modal from "../../components/Modal";
import i18n from "../../i18n";
import {webviewShowBanner} from "../../utils/webview";

export default class SubscribeModal extends Modal {

  componentDidMount() {
    window.webviewEventsListeners.backPress.push(() => {
      this.dismiss();
      return true;
    });
  }

  dismiss(trigger) {
    window.webviewEventsListeners.backPress.pop();
    super.dismiss(trigger);
  }

  handleGoProButtonClick = () => {
    webviewShowBanner("avatars_indo");
  };

  renderModal() {
    return <React.Fragment>
      <h1 dangerouslySetInnerHTML={{__html: i18n.t("subscribe_modal_title")}} />
      <p dangerouslySetInnerHTML={{__html: i18n.t("subscribe_modal_message")}} />
      <button
        className="btn btn-white"
        onClick={this.handleGoProButtonClick}>
        {i18n.t("subscribe_modal_button_go_pro")}
      </button>

      <button
        className="btn btn-transparent mt-24"
        onClick={() => this.dismiss()}>
        {i18n.t("subscribe_modal_button_not_now")}
      </button>
    </React.Fragment>;
  }
}
