import React from "react";
import {Loader} from "../../components/Loader";

export default class ImageView extends React.Component {

  state = {
    isLoaded: false,
  };

  render() {
    const {imageUrl} = this.props;

    const classNames = ["image-item"];
    !this.state.isLoaded && classNames.push("loading");

    return <div className={classNames.join(" ")}>
      <div className="holder">
        <Loader hidden={this.state.isLoaded} />
        <img
          style={{opacity: this.state.isLoaded ? 1 : 0}}
          onLoad={() => {this.setState({isLoaded: true})}}
          src={imageUrl}
          loading="lazy"
          alt="" />
      </div>
    </div>;
  }
}