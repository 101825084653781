import React from "react";
import i18n from "../../i18n";
import AppContext from "../../contexts/AppContext";
import neuroApiHelper from "../../helpers/neuro-api.helper";

export default class LatestPacksView extends React.Component {

  getResultImages = () => {
    const packs = this.context.packs
      .sort((a,b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      .filter((p) => p.status === neuroApiHelper.packStatus.ready)
      .slice(0, 3);

    const images = [];

    for (let i = 0; i < 3; i++) {
      packs.forEach((p) => {
        if (images.length === 3) {
          return;
        }

        images.push(p.results[i]);
      });
    }

    return images;
  }

  render() {
    if (this.props.hidden) {
      return <React.Fragment />;
    }

    const resultImages = this.getResultImages();
    const firstPack = this.context.packs.first();
    const isPending = resultImages.length === 0 && firstPack;
    const images = this.context.packs.isEmpty()
      ? window.appConfig.samplePack.results.slice(0, 3)
      : (isPending ? firstPack.images.slice(0, 3) : resultImages);

    const text = this.context.packs.isEmpty()
      ? i18n.t("button__go_to_sample_pack")
      : i18n.t("latest_packs_title");

    if (images.isEmpty()) {
      return <React.Fragment />;
    }

    return <div
      className={`previous-avatars-container ${this.props.className || ""}`}
      onClick={this.props.onGoToClick}>
      <p>
        <span dangerouslySetInnerHTML={{__html: text}} />
        <svg viewBox="0 0 16 16" fill="none">
          <path d="m8.375 3.5 4.5 4.5-4.5 4.5M12.25 8H3.125" stroke="#fff" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <svg className="icon-arrow-v2" viewBox="0 0 25 8" fill="none">
            <path d="M24.354 4.354a.5.5 0 0 0 0-.708L21.172.464a.5.5 0 1 0-.707.708L23.293 4l-2.828 2.828a.5.5 0 1 0 .707.708l3.182-3.182zM0 4.5h24v-1H0v1z" fill="#F8D34F"/>
        </svg>
      </p>

      <div className="previous-avatars">
        {images.map((imageUrl) => <div key={imageUrl} className={"previous-avatar" + (isPending ? " pending" : " processed")}>
          <img src={imageUrl} alt="" />
          {isPending && <div className="pending-loader-container">
            <div className="pending-loader">
              <div className="pending-loader-slider" />
            </div>
          </div>}
        </div>)}
      </div>
    </div>;
  }
}

LatestPacksView.contextType = AppContext;
