import axios from "axios";
import {debounce} from "./etc";

let webviewParamsIsCommited = false;

const userProperties = {
  client_type: window.clientConfig.isWebview ? "webview" : (window.clientConfig.isWebMobile ? "mobile" : "desktop"),
  is_webview: window.clientConfig.isWebview,
  is_mobile: window.clientConfig.isWebMobile,
  is_mobile_desktop_mode: window.clientConfig.isWebMobileDesktopMode,
  platform_browser_name: window.clientConfig.platform.name,
  platform_browser_version: window.clientConfig.platform.version,
  platform_os: window.clientConfig.platform.os,
  screen_w: window.screen.width,
  screen_h: window.screen.height,
  viewport_w: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
  viewport_h: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
  locale: window.clientConfig.locale,
  is_pro: window.clientConfig.isPro,
  split_group: window.clientConfig.splitGroupId,
};

if (window.clientConfig.isWebview) {
  const osName = window.clientConfig.isWebviewAndroid
    ? "Android"
    : "iOS";

  userProperties.os_version = `${osName} ${window.clientConfig.webviewParams.os_version}`;
  userProperties.native_app_build = `${osName} ${window.clientConfig.webviewParams.version_code}`;
}

const userPropertiesCommitWaited = Object.keys(userProperties);

if (window.appConfig.isDebug) {
  console.log("initial user properties", userProperties);
}

// --

export const userEvents = {
  INDEX_VISIT: "index_visit",
  INDEX_GO_PRESS: "index_go_press",
  INDEX_GO_PRESS_LIMIT_REACHED: "index_go_press_limit_reached",
  INDEX_GO_PRESS_SUBSCRIBE: "index_go_press_subscribe",
  CREATE_VISIT: "create_visit",
  CREATE_PROCEED_PRESS: "create_proceed_press",
  PHOTO_SELECT: "photo_select",
  PHOTO_SELECT_MINIMUM: "photo_select_min",
  PACK_CREATE_REQUEST: "pack_create_request",
  PACK_CREATED: "pack_created",
  PACK_VISIT: "pack_visit",
  DOWNLOAD: "download",
  SAMPLE_GO_PRESS: "sample_go_press",
  CREATIVE_STARTED: "creative_started",
  CREATIVE_PROCESSED: "creative_processed",
  CREATIVE_FAILED: "creative_failed",
  COMPONENT_ERROR: "component_error",
  JS_GLOBAL_ERROR: "js_global_error",
  DISCLAIMER_SHOWN: "disclaimer_shown",
  UPDATE_APP_MODAL_SHOWN: "update_app_modal_shown",
  UPDATE_APP_MODAL_PRESS_CONFIRM: "update_app_modal_press_confirm",
  ETH_CONNECT_CLICK: "eth_connect_click",
  ETH_CONNECT_START: "eth_connect_start",
  ETH_CONNECT_ERROR: "eth_connect_error",
  ETH_CONNECT_OK: "eth_connect_ok",
  ETH_CONNECT_FAILURE: "eth_connect_failure",
  ETH_SIGN_CLICK: "eth_sign_click",
  ETH_SIGN_START: "eth_sign_start",
  ETH_SIGN_ERROR: "eth_sign_error",
  ETH_SIGN_OK: "eth_sign_ok",
};

export const hits = {
  indexVisit: 9310,
  indexGoPress: 9311,
  packVisit: 9312,
  packProcessingShown: 9313,
  packCreated: 9316,
  packProcessed: 9318,
  userSelectPhoto: 9317,
  download: 9319,
  firstDownloadByPack: 9331,
  sampleGoPress: 0,
  //
  indexVisitOnce: 9332,
  indexGoPressOnce: 9333,
  selectFirstPhotoOnce: 9334,
  selectMinimumPhotosOnce: 9335,
  createProceedPressOnce: 9336,
  packCreatedOnce: 9337,
  packResultOnce: 9338,
  downloadOnce: 9339,
  updateAppModalShown: 9369,
  updateAppModalPressConfirm: 9370,

  createPackLambdaRequestError: 9392,
  packsLambdaRequestError: 9391,
  photolabLambdaRequestError: 9419,
  analyticsLambdaRequestError: 9417,
  timingsLambdaRequestError: 9418,
  jsGlobalError: 9601
};

const hitsCache = {};
export function hitEvent(id, count = 1, ignoreUserGroup = false, delay = 1000) {
  const config = window.appConfig.hits;

  if (id === 0) {
    console.warn("Zero-valued hit triggered");
    return;
  }

  if (window.appConfig.isDebug) {
    const hitName = Object.keys(hits).find((key) => hits[key] === id) || "(unknown)";
    console.info("hitEvent", JSON.stringify({hitName, id, count, ignoreUserGroup}));
  }

  if (!config.isEnabled) {
    return;
  }

  if (ignoreUserGroup || config.allowedUserGroups.includes(window.clientConfig.splitGroupId)) {
    hitsCache[id] = (hitsCache[id] || 0) + count;
    debounce("hitEvent." + id, delay, () => {
      const c = hitsCache[id] || 1;
      hitsCache[id] = 0;

      window.axios.post(`${config.endpoint}?id=${id}&c=${c}&r=${Date.now()}`)
        .then(() => {/* dummy */})
        .catch(console.error);
    });
  }
}

export function hitEventOnce(id, keySuffix = "", count = 1, ignoreUserGroup = false, delay = 1000) {
  let storageKey = window.appConfig.project.name + ":hitEventOnce_" + id;
  if (typeof keySuffix === "string" && keySuffix.length > 0) {
    storageKey += "_" + keySuffix;
  }

  if (window.localStorage.getItem(storageKey) !== "1") {
    hitEvent(id, count, ignoreUserGroup, delay);
    window.localStorage.setItem(storageKey, "1");
  }
}

export function logEvent(eventId, eventParams, cb) {
  eventParams = eventParams || {};
  eventParams.build_version = window.appConfig.build.version;

  if (window.appConfig.isDebug) {
    console.debug("logEvent", eventId, JSON.stringify(eventParams));
  }

  if (window.appConfig.analytics.isEnabled) {
    const isAllow = !window.appConfig.analytics.overloadModeIsEnabled
      ||
      (window.appConfig.analytics.overloadModeIsEnabled
        && window.appConfig.analytics.overloadModeEvents.includes(eventId))
    ;

    if (isAllow) {
      _logEvent(eventId, eventParams).then(() => {
        cb && cb();
      });
    }
  }
}

function _logEvent(eventId, eventParams) {
  let userParams = undefined;
  if (userPropertiesCommitWaited.length > 0) {
    userParams = {};
    userPropertiesCommitWaited.forEach((key) => userParams[key] = userProperties[key]);
    userPropertiesCommitWaited.length = 0;
  }

  let webviewParams = undefined;
  if (window.clientConfig.isWebview && !webviewParamsIsCommited) {
    webviewParamsIsCommited = true;
    webviewParams = window.clientConfig.webviewParams;
  }

  return axios.post(window.appConfig.analytics.endpoint, {
    client_token: window.clientConfig.token,
    client_params: userParams,
    client_webview_params: webviewParams,
    project_name: window.appConfig.project.name,
    event_name: eventId,
    event_params: eventParams,
    locale: window.clientConfig.locale,
  }).then((response) => {
    return response.data;
  }).then((data) => {
    if (window.clientConfig.geoipCountryCode) {
      return;
    }

    if (data.client) {
      window.clientConfig.geoipCountryCode = data.client.geoip_country_code;
      return;
    }

    try {
      const locale = new Intl.Locale(window.navigator.language);
      window.clientConfig.geoipCountryCode = locale.region || null;
    } catch (e) {/* skip */}
  }).catch((err) => {
    hitEvent(hits.analyticsLambdaRequestError);
    console.error(err);
  });
}

export function setUserProperty(key, value) {
  if (window.appConfig.isDebug) {
    console.debug("setUserProperty", key, value);
  }

  const currentValue = userProperties[key];
  if (currentValue !== value) {
    userProperties[key] = value;
    userPropertiesCommitWaited.push(key);
  }
}

export function logProcessingsTimings(time) {
  if (!window.appConfig.processingTimings.isEnabled) {
    return Promise.reject();
  }

  return axios.post(window.appConfig.processingTimings.endpoint, {
    project_name: window.appConfig.project.name,
    time,
  }).then(() => {
    /* skip */
  }).catch((err) => {
    hitEvent(hits.timingsLambdaRequestError);
    console.error(err);
  });
}
