import React from "react";
import Modal from "../../components/Modal";

export default class MessageModal extends Modal {

  componentDidMount() {
    window.webviewEventsListeners.backPress.push(() => {
      this.dismiss();
      return true;
    });
  }

  dismiss(trigger) {
    window.webviewEventsListeners.backPress.pop();
    super.dismiss(trigger);
  }

  renderModal() {
    return <React.Fragment>
      <h1
        hidden={!this.props.headingText}
        dangerouslySetInnerHTML={{__html: this.props.headingText}}
      />
      <p
        hidden={!this.props.messageText}
        dangerouslySetInnerHTML={{__html: this.props.messageText}}
      />
      <button
        className="btn btn-white"
        children={this.props.buttonText}
        onClick={() => this.dismiss()} />
    </React.Fragment>;
  }
}
