import React from "react";
import i18n from "../i18n";
import {assetUrl} from "../utils/etc";

export default class ErrorView extends React.Component {

  state = {
    message: "",
  };

  componentDidMount() {
    let message = i18n.t("error__default_message");
    this.setState({message});
  }

  render() {
    return <main className="error-page">
      <div className="container">
        <div className="error-content">
          <img src={assetUrl("assets/images/rocketman.svg")} alt="." />
          {/* <h3>{i18n.t("error__error")}</h3> */}
          <p>{this.state.message}</p>
        </div>
      </div>
    </main>;
  }
}