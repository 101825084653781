import React from "react";
import i18n from "../../i18n";
import CountdownTimer from "../shared/CountdownTimer";
import clientStorage from "../../utils/client-storage";
import SliderView from "./SliderView";
import {assetUrl, debounce} from "../../utils/etc";
import {isWebviewApp} from "../../utils/config.utils";
import {webviewAppIds} from "../../utils/webview";
import {defaultSlides, newprofilepicSlides, toonmeSlides} from "./shared";
import processingManager from "../../photolab/ProcessingManager";

function getSlidesByApp() {
  if (isWebviewApp(webviewAppIds.toonme)) {
    return toonmeSlides.slice();
  } else if (isWebviewApp(webviewAppIds.newprofilepic)) {
    return newprofilepicSlides.slice();
  } else {
    return defaultSlides.slice();
  }
}

export default class ProcessingView extends React.Component {

  state = {
    timerExpired: false,
    slides: [],
    isShowSlider: false,
  };

  componentDidMount() {
    processingManager.addOnProcessingChangeHandler(this.handleProcessingChanged);

    if (processingManager.processing === null) {
      const processing = processingManager.restore();
      if (processing) {
        processingManager.start(processing);
      } else {
        this.setState({
          isShowSlider: true,
          slides: getSlidesByApp(),
        });
      }
    } else {
      this.handleProcessingChanged();
    }
  }

  componentWillUnmount() {
    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChanged);
  }

  handleProcessingChanged = () => debounce("ProcessingView_handleProcessingChanged", 100, () => {
    if (window.appConfig.isDebug) {
      const cloned = JSON.parse(processingManager.processing.toJSON(true));
      console.info("ProcessingView::handleProcessingChanged", cloned);
    }

    const creatives = processingManager.processing.creatives
      .filter((_) => _.isProcessed);

    if (creatives.length < 3) {
      this.setState({
        slides: getSlidesByApp(),
        isShowSlider: !isWebviewApp(webviewAppIds.newprofilepic) && !isWebviewApp(webviewAppIds.toonme),
      });
      return;
    }

    const slides = creatives.map((_) => {
      return {img: _.getFile("raw")
    }});

    this.setState({
      slides,
      isShowSlider: true,
    });
  });

  handleTimerExpired = () => {
    this.setState({timerExpired: true});
  };

  getTimerDateMillis = () => {
    let timerDateMillis = clientStorage.getPackProgressCountdown(this.props.pack.id);

    if (!timerDateMillis) {
      let period = Math.floor((this.props.pack.progress.countdown || 0) * 1000);

      // ¯\_(ツ)_/¯
      if (period > 60_000 * 35) {
        period -= 60_000 * 35;
      } else if (period > 60_000 * 6) {
        period -= 60_000 * 6;
      }

      timerDateMillis = new Date(this.props.pack.created_at).getTime() + period;

      clientStorage.setPackProgressCountdown(this.props.pack.id, timerDateMillis);
    }

    return timerDateMillis;
  }

  renderTimer() {
    if (!this.props.pack) {
      return <p className="notice-text">&nbsp;</p>;
    }

    const timerDateMillis = this.getTimerDateMillis();
    const timerIsVisible = timerDateMillis > Date.now() && !this.state.timerExpired;
    const timerIsExpired = timerDateMillis <= Date.now() || this.state.timerExpired;

    return <React.Fragment>
      {timerIsVisible && <CountdownTimer
        targetMillis={timerDateMillis}
        onExpire={this.handleTimerExpired}
      />}
      <p
        className="notice-text"
        dangerouslySetInnerHTML={{__html: i18n.t("processing_expired_text")}}
        hidden={!timerIsExpired}
      />
    </React.Fragment>
  }

  handleGoToAnotherStaffClick = () => {
    this.props.onGoToAnotherStaffClick && this.props.onGoToAnotherStaffClick();
  };

  render() {
    const invisibleStyles = this.state.isShowSlider
      ? {}
      : {
        visibility: "hidden",
        pointerEvents: "none",
      };

    return <main className="loader">
      <div className="container">
        <header>
          <button onClick={this.props.onBackClick} hidden={!this.props.canBack}>
            <svg viewBox="0 0 24 24">
              <path d="m5.7 10.5 8.4-8.4L12 0 0 12l12 12 2.1-2.1-8.4-8.4H24v-3z" fill="#fff" fillRule="evenodd"/>
            </svg>
          </button>
          <h1>{i18n.t("processing_title")}</h1>
        </header>

        <div className="loader-content">
          <img
            className="icon-clock"
            src={assetUrl("assets/images/icon-clock.png")}
            alt=""
          />

          {/*<LatestPacksView onGoToClick={this.props.onGoToPacksPage} />*/}
          {this.renderTimer()}

          <p
            className="loader-content-text-1"
            dangerouslySetInnerHTML={{__html: i18n.t("processing_text_1")}}
          />
          <p
            className="loader-content-text-2"
            style={invisibleStyles}
            dangerouslySetInnerHTML={{__html: i18n.t("processing_text_2")}}
          />

          <div className="loader-slider" style={invisibleStyles}>
            <SliderView slides={this.state.slides} />
          </div>

          <button
            style={invisibleStyles}
            className="btn btn-transparent"
            onClick={this.handleGoToAnotherStaffClick}>
            {i18n.t("button_more")}
          </button>
        </div>
      </div>
    </main>;
  }
}