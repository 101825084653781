import React from "react";
import Modal from "../../components/Modal";
import i18n from "../../i18n";

export default class DifferentPeopleModal extends Modal {

  constructor(props) {
    super(props);
    this.className = "popup-overlay-same";
  }

  componentDidMount() {
    window.webviewEventsListeners.backPress.push(() => {
      this.dismiss();
      return true;
    });
  }

  dismiss(trigger) {
    window.webviewEventsListeners.backPress.pop();
    super.dismiss(trigger);
  }

  renderModal() {
    const unmatched = this.props.data.unmatched;
    const multipleFaces = this.props.data.multiple_faces;

    return <React.Fragment>
      {unmatched.isNotEmpty() && <div className="popup-same">
        <div className="popup-images">
          <img className="circle" src={this.props.data.target} alt=""/>
          {unmatched.map((image) => <img key={image} src={image} alt=""/>)}
        </div>

        <h2 dangerouslySetInnerHTML={{__html: i18n.t("different_people_modal__unmatched_title")}}/>
        <p dangerouslySetInnerHTML={{__html: i18n.t("different_people_modal__unmatched_text")}}/>
      </div>}

      {unmatched.isEmpty() && <div className="popup-same">
        <div className="popup-images">
          {multipleFaces.map((image) => <img key={image} src={image} alt=""/>)}
        </div>

        <p dangerouslySetInnerHTML={{__html: i18n.t("different_people_modal__multiple_faces_text")}}/>
      </div>}

      <button
        className="btn btn-white"
        children={i18n.t("different_people_modal__button_different_people_cancel")}
        onClick={() => this.dismiss()} />

      <button
        className="btn btn-white-transparent"
        children={i18n.t("different_people_modal__button_different_people_confirm")}
        onClick={() => {
          this.dismiss();
          this.props.onConfirm && this.props.onConfirm();
        }} />
    </React.Fragment>;
  }
}
