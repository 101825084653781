import React from "react";
import AppContext from "../contexts/AppContext";

export default class ModalWrapper extends React.Component {

  constructor(props) {
    super(props);

    this.getUpperModal = this.getUpperModal.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener("keydown", this.handleKeydown);
  }

  componentWillUnmount() {
    document.body.removeEventListener("keydown", this.handleKeydown);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.context.modals.length) {
      document.body.classList.add("app--shown-modals");
    } else {
      document.body.classList.remove("app--shown-modals");
    }
  }

  getUpperModal() {
    return this.context.modals.length
      ? this.context.modals[this.context.modals.length - 1]
      : null;
  }

  handleKeydown(e) {
    const modal = this.getUpperModal();
    if (e.key === "Escape" && modal && modal.props.closeByEscape) {
      if (modal.props.onDismiss) {
        if (modal.props.onDismiss("keydown") !== false) {
          this.context.popModal(() => {
            modal.props.onDismissed && modal.props.onDismissed("keydown");
          });
        }
      } else {
        this.context.popModal(() => {
          modal.props.onDismissed && modal.props.onDismissed("keydown");
        });
      }
    }
  }

  render() {
    return this.context.modals;
  }
}

ModalWrapper.contextType = AppContext;
