import React from "react";
import Modal from "../../components/Modal";
import i18n from "../../i18n";

export default class ImageErrorModal extends Modal {

  constructor(props) {
    super(props);
    this.className = "";
  }

  componentDidMount() {
    window.webviewEventsListeners.backPress.push(() => {
      this.dismiss();
      return true;
    });
  }

  dismiss(trigger) {
    window.webviewEventsListeners.backPress.pop();
    super.dismiss(trigger);
  }

  handleConfirmButtonClick = () => {
    this.props.onOkClick && this.props.onOkClick();
    this.dismiss();
  }

  renderModal() {
    const error = this.props.image.error;
    const isPhotolabError = error.type === "photolab";
    const isPhotolabSystemError = isPhotolabError && error.code > -1000;

    const isNetworkError = (error.type === "network")
      || (error.message === "Network Error")
      || (error.name === "NetworkError")
      || (navigator.onLine === false);

    let message = "";
    if (isNetworkError) {
      message = i18n.t("error__network_message");
    } else if (isPhotolabSystemError) {
      message = i18n.t("error__overload");
    } else if (isPhotolabError) {
      message = error.message;
    } else {
      message = i18n.t("error__default_message");
    }

    return <React.Fragment>
      <p>{message}</p>
      <button
        className="btn btn-white"
        onClick={this.handleConfirmButtonClick}>
        {i18n.t("button__ok")}
      </button>
    </React.Fragment>;
  }
}
