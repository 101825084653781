import CreativeConfig from "../CreativeConfig";
import Creative from "../Creative";
import {handlersNames} from "../handlers";

export const genders = {
  male: "male",
  female: "female",
};

export const apiWatermarkStep = {
  type: "api_watermark",
  setAsFile: "raw_watermark",
};

export const photolabWatermarkStep = {
  type: "template",
  id: 6826,
  setAsFile: "raw_watermark",
};

export const frontendWatermarkStep = {
  type: "watermark",
  setAsFile: "raw_watermark",
  watermark: {
    url: "/assets/images/watermarks/default_4.png",
    position: "bottom-right",
    x: 3, // %
    y: 2, // %
    percentage: 30,
  },
}

export const frontendWatermarkPreviewConfig = {
  url: "/assets/images/watermarks/default_4.png",
  position: "bottom-right",
  x: 3, // %
  y: 7, // %
  percentage: 30,
}

/**
 * @param {array} steps
 * @returns {CreativeConfig}
 */
export function combo(steps) {
  steps = steps
    .filter((step) => !!step)
    .map((id) => {
      return Number.isInteger(id)
        ? {id, images: [{useAltBody: true}]}
        : id;
    });

  // if (whenSplitInRange(1, 5)) {
  //   steps.unshift({id: 7150, images: [{useAltBody: true}]});
  // }

  if (!steps.find((s) => s.setAsFile === "raw")) {
    steps.last().setAsFile = "raw";
  }

  const comboId = steps.map((step) => step.id).join("_");

  return new CreativeConfig("nogroup", comboId, handlersNames.COMBO)
    .setExtra(Creative.EXTRA_COMBO_STEPS, steps);
}

export function whenSplit() {
  const items = (arguments.length > 0 && Array.isArray(arguments[0]))
    ? arguments[0]
    : arguments;

  return [].indexOf.call(items, window.clientConfig.splitGroupId) > -1;
}

export function whenSplitInRange(start, end) {
  return window.clientConfig.splitGroupId >= start
    && window.clientConfig.splitGroupId <= end;
}

export function setCreativesGroup(group, configs) {
  configs.forEach((config) => config.setGroup(group));

  return configs;
}

export function setCreativesVisibleAfterAt() {
  let at = window.appConfig.processings.creativesRollout.at.getTime();
  let configs = arguments[0];

  if (arguments.length === 2) {
    at = arguments[0];
    configs = arguments[1];
  }

  if (typeof at === "string") {
    at = (new Date(at)).getTime();
  }

  configs.forEach((config) => config.setVisibleAfterAt(at));

  return configs;
}

export function setCreativesHiddenAfterAt() {
  let at = window.appConfig.processings.creativesRollout.at.getTime();
  let configs = arguments[0];

  if (arguments.length === 2) {
    at = arguments[0];
    configs = arguments[1];
  }

  if (typeof at === "string") {
    at = (new Date(at)).getTime();
  }

  configs.forEach((config) => config.setHiddenAfterAt(at));

  return configs;
}

export function stepWithFallback(id, fallbackId, extra = {}) {
  return {
    ...{id, fallbackId, images: [{useAltBody: true}]},
    ...extra,
  };
}

export function skipOnMultifaceError(id, extra = {}) {
  return {
    ...{id, skipOnMultifaceError: true, images: [{useAltBody: true}]},
    ...extra,
  };
}

// перемешивает массив конфигов оставляя isSelected первым в списке
export function getCreativesInRandomOrder(configs) {
  const configsWithPosition = configs
    .filter((c) => c.getExtra(Creative.EXTRA_POSITION, -1) !== -1)
    .sort((a, b) => parseInt(a.getExtra(Creative.EXTRA_POSITION)) - parseInt(b.getExtra(Creative.EXTRA_POSITION)));

  if (configsWithPosition.length === 0) {
    return configs.shuffle();
  }

  let shuffleConfigs = configs
    .filter((c) => c.getExtra(Creative.EXTRA_POSITION, -1) === -1)
    .shuffle()
    .slice();

  configsWithPosition.forEach((c) => {
    const extraPosition = parseInt(c.getExtra(Creative.EXTRA_POSITION));

    shuffleConfigs.splice(extraPosition, 0, c);
  });

  return shuffleConfigs;
}