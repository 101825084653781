let userClientData = {};

function getFullPath(path) {
  const url = new URL(window.appConfig.paths.backend);
  url.pathname += "/" + path;

  for (let key in userClientData) {
    url.searchParams.append(key, userClientData[key]);
  }

  return url.toString();
}

function defaultHandler(res) {
  if (res.ok) {
    return res.json();
  } else {
    const error = new Error("NeuroavatarsResponseError");
    error.name = "NeuroavatarsResponseError";
    error.response = res.clone();

    throw error;
  }
}

function networkErrorHandler(err) {
  const error = new Error("Network error");
  error.name = "NetworkError";
  error.parentError = err;

  throw error;
}

function defaultErrorHandler(err) {
  throw err;
}

function setUserClientData(data) {
  userClientData = data;
}

function call(path, params) {
  return fetch(getFullPath(path), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })
    .catch(networkErrorHandler)
    .then(defaultHandler)
    .catch(defaultErrorHandler)
}

function packsCheckAddress(address) {
  return call("packs.check-address", {address});
}

function packsCreate(images, gender, address, signature) {
  return call("packs.create", {
    images,
    gender,
    address,
    signature,
    platform: window.clientConfig.isWebviewAndroid ? "android" : "ios",
  });
}

export default {
  setUserClientData,
  packsCheckAddress,
  packsCreate,
};