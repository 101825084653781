import React from "react";

export function Loader({hidden, className}) {
  if (hidden) {
    return <React.Fragment />;
  }

  const classNames = [
    "pending-loader-container",
    className,
  ].filter(Boolean);

  return <div className={classNames.join(" ")}>
    <div className="pending-loader">
      <div className="pending-loader-slider" />
    </div>
  </div>;
}