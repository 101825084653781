import React from "react";
import AppContext from "../../contexts/AppContext";
import routes from "../../routes";
import {assetUrl} from "../../utils/etc";
import i18n from "../../i18n";
import ImageView from "../packs/ImageView";
import {hitEvent, hits, logEvent, userEvents} from "../../utils/log";

export default class SamplePackPage extends React.Component {

  state = {
    pack: window.appConfig.samplePack,
  };

  componentDidMount() {
    this.context.hideLoader();

    window.webviewEventsListeners.backPress.push(() => {
      this.props.history.goBack();
      return true;
    });
  }

  componentWillUnmount() {
    window.webviewEventsListeners.backPress.pop();
  }

  handleStartClick = () => {
    hitEvent(hits.sampleGoPress);
    logEvent(userEvents.SAMPLE_GO_PRESS);
    this.props.history.replace(routes.INDEX);
  };

  handleBackClick = () => {
    this.props.history.replace(routes.INDEX);
  }

  render() {
    if (!this.state.pack) {
      return <React.Fragment />;
    }

    return <div className="container container-pack">
      <header>
        <button onClick={this.handleBackClick}>
          <svg viewBox="0 0 24 24"><path d="m5.7 10.5 8.4-8.4L12 0 0 12l12 12 2.1-2.1-8.4-8.4H24v-3z" fill="#fff" fillRule="evenodd"/></svg>
        </button>
        <h1>{i18n.t("sample_pack__title")}</h1>
      </header>

      <div>
        <p dangerouslySetInnerHTML={{__html: i18n.t("sample_pack__text")}} />
      </div>
      <div className="originals-pack">
        <p dangerouslySetInnerHTML={{__html: i18n.t("sample_pack__originals_text")}} />
        <div className="originals-items">
          {this.state.pack.originals.map((image) => <ImageView key={image} imageUrl={image} />)}
        </div>
      </div>
      <div className="results-pack">
        <p>
          <img src={assetUrl("assets/images/icon-magic.png")} alt="" />
          <span dangerouslySetInnerHTML={{__html: i18n.t("sample_pack__results_text")}} />
        </p>
        <div className="results-items">
          {this.state.pack.results.map((image) => <ImageView key={image} imageUrl={image} />)}
        </div>
      </div>

      <div className="btn-container">
        <button
          className="btn btn-shadow btn-violet"
          onClick={this.handleStartClick}>
          {i18n.t("btn_create_avatars")}
        </button>
      </div>
    </div>;
  }
}

SamplePackPage.contextType = AppContext;
