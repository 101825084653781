import React from "react";
import ImageView from "./ImageView";
import neuroApiHelper from "../../helpers/neuro-api.helper";
import i18n from "../../i18n";

export default function PackItemView({pack, onGoToClick}) {
  const createdAt = new Date(pack.created_at);
  const formattedDate = createdAt.getDate() + " "
    + i18n.t("month_short_" + createdAt.getMonth()) + " "
    + createdAt.getFullYear();

  const images = pack.status === neuroApiHelper.packStatus.ready
    ? pack.results.slice(0, window.appConfig.freeResultsThreshold)
    : pack.images;

  return <div className="pack-grid" onClick={() => onGoToClick(pack)}>
    <div className="pack-grid-date">
      <p className="pack-grid-date">{formattedDate}</p>
      <svg viewBox="0 0 16 16" fill="none">
        <path d="m8.375 3.5 4.5 4.5-4.5 4.5M12.25 8H3.125" stroke="#fff" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </div>

    {pack.status === neuroApiHelper.packStatus.ready && <div className="images-grid">
      {images.map((imageUrl) => <ImageView key={imageUrl} imageUrl={imageUrl} />)}
    </div>}

    {pack.status === neuroApiHelper.packStatus.pending && <div className="">
      <p>pending...</p>
    </div>}
  </div>;
}