import {assetUrl} from "../../utils/etc";

export const defaultSlides = [
  {img: assetUrl("assets/images/slider/1.jpg")},
  {img: assetUrl("assets/images/slider/2.jpg")},
  {img: assetUrl("assets/images/slider/3.jpg")},
  {img: assetUrl("assets/images/slider/4.jpg")},
  {img: assetUrl("assets/images/slider/5.jpg")},
  {img: assetUrl("assets/images/slider/6.jpg")},
  {img: assetUrl("assets/images/slider/7.jpg")},
  {img: assetUrl("assets/images/slider/8.jpg")},
];

export const toonmeSlides = [
  {img: assetUrl("assets/images/slider/toonme/1.jpg")},
  {img: assetUrl("assets/images/slider/toonme/2.jpg")},
  {img: assetUrl("assets/images/slider/toonme/3.jpg")},
  {img: assetUrl("assets/images/slider/toonme/4.jpg")},
]

export const newprofilepicSlides = [
  {img: assetUrl("assets/images/slider/npp/1.jpg")},
  {img: assetUrl("assets/images/slider/npp/2.jpg")},
  {img: assetUrl("assets/images/slider/npp/3.jpg")},
  {img: assetUrl("assets/images/slider/npp/4.jpg")},
]