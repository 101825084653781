function defaultHandler(res) {
  // if (res.ok) {
    return res.json();
  // } else {
  //   const error = new Error("NeuroApiResponseError");
  //   error.name = "NeuroApiResponseError";
  //   error.response = res.clone();
  //
  //   throw error;
  // }
}

function networkErrorHandler(err) {
  const error = new Error("Network error");
  error.name = "NetworkError";
  error.parentError = err;

  throw error;
}

function defaultErrorHandler(err) {
  throw err;
}

function compare(images) {
  return fetch(window.appConfig.paths.faceComparisonApi, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({"urls": images}),
  })
    .catch(networkErrorHandler)
    .then(defaultHandler)
    .catch(defaultErrorHandler)
}

export default {
  compare,
};