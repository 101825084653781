import React from "react";
import {Loader} from "./Loader";

export default class Loading extends React.Component {

  render() {
    return <main className="loader loader-fixed" hidden={this.props.isHidden}>
      <Loader />
    </main>;
  }
}