const tag = "webview.helper";

function log(message) {
  console.log(`[${tag}] ${message}`);
}

function logError(message) {
  console.error(`[${tag}] ${message}`);
}

function isAndroidRuntimeApiAvailable() {
  return typeof window.VicmanNativeApi !== "undefined";
}

function isIosRuntimeApiAvailable() {
  return typeof window.webkit !== "undefined"
    && typeof window.webkit.messageHandlers !== "undefined"
    && typeof window.webkit.messageHandlers.VicmanNativeApi !== "undefined";
}

function nativeAsyncCall(action, params = {}) {
  log(`call ${action}: ${JSON.stringify(params)}`);

  if (isAndroidRuntimeApiAvailable()) {
    window.VicmanNativeApi[action](JSON.stringify(params));
  } else if (isIosRuntimeApiAvailable()) {
    window.webkit.messageHandlers.VicmanNativeApi.postMessage({action, json: params});
  } else {
    console.error("");
  }
}

function nativeSyncCallAndroid(action, params = {}) {
  log(`call ${action}: ${JSON.stringify(params)}`);

  const result = window.VicmanNativeApi[action](JSON.stringify(params));
  if (result !== undefined) {
    const parsed = JSON.parse(result);
    log(`result of sync ${action}: ${result}`);
    return parsed;
  }

  return undefined;
}

function createFunc(name) {
  const funcName = `${name}_${Date.now()}`;

  return {
    name: funcName,
    promise: new Promise((resolve) => {
      window[funcName] = function(data) {
        log(`response ${funcName}: ${typeof data === "string" ? data : JSON.stringify(data)}`);

        if (typeof data === "string" && (data[0] === "{" || data[0] === "[")) {
          data = JSON.parse(data);
        }

        resolve(data);
      };
    }),
  };
}

export default {

  /**
   * @param {object} extra
   * @returns {Promise<{eip155?: {methods: array<string>, accounts: array<string>}, error?: {code?: *, message: string}, web_extra?: *}>}
   */
  walletconnectGetState: (extra = {}) => {
    const callbackPromisifiedFunc = createFunc("walletconnectGetState_func");

    if (window.clientConfig.isWebviewAndroid) {
      return new Promise((resolve) => {
        const res = nativeSyncCallAndroid("walletconnectGetState", {
          func: callbackPromisifiedFunc.name,
          web_extra: {...extra},
        });

        if (res !== undefined) {
          resolve(res);
        } else {
          callbackPromisifiedFunc.promise.then(resolve);
        }
      });
    } else {
      nativeAsyncCall("walletconnectGetState", {
        func: callbackPromisifiedFunc.name,
        web_extra: {...extra},
      });

      return callbackPromisifiedFunc.promise;
    }
  },

  /**
   * @param {object} extra
   * @returns {Promise<{address?: string, name?: string, error?: {code?: *, message: string}, web_extra?: *}>}
   */
  walletconnectAuth: (extra = {}) => {
    const callbackPromisifiedFunc = createFunc("walletconnectAuth_func");

    nativeAsyncCall("walletconnectAuth", {
      func: callbackPromisifiedFunc.name,
      web_extra: {...extra},
    });

    return callbackPromisifiedFunc.promise;
  },

  /**
   * @param {object} extra
   * @returns {Promise<{result?: string, error?: {code?: *, message: string}, web_extra?: *}>}
   */
  walletconnectReset: (extra = {}) => {
    const callbackPromisifiedFunc = createFunc("walletconnectReset_func");

    if (window.clientConfig.isWebviewAndroid) {
      return new Promise((resolve) => {
        const res = nativeSyncCallAndroid("walletconnectReset", {
          func: callbackPromisifiedFunc.name,
          web_extra: {...extra},
        });

        if (res !== undefined) {
          resolve(res);
        } else {
          callbackPromisifiedFunc.promise.then(resolve);
        }
      });
    } else {
      nativeAsyncCall("walletconnectReset", {
        func: callbackPromisifiedFunc.name,
        web_extra: {...extra},
      });

      return callbackPromisifiedFunc.promise;
    }
  },

  /**
   * @param {string} account - CAIP-10 account string (e.g. eip155:1:0xfff...)
   * @param {string} message - base64 encoded string
   * @param {string} chain
   * @param {string} method
   * @param {object} extra
   * @returns {Promise<{message?: string, signature?: string, error?: {code?: *, message: string}, web_extra?: *}>}
   */
  walletconnectSign: (
    account,
    message,
    chain = "eip155:1",
    method = "personal_sign",
    extra = {}
  ) => {
    const callbackPromisifiedFunc = createFunc("walletconnectSign_func");

    nativeAsyncCall("walletconnectSign", {
      account,
      message,
      chain,
      method,
      func: callbackPromisifiedFunc.name,
      web_extra: {...extra},
    });

    return callbackPromisifiedFunc.promise;
  },
}
