import {isWebviewApp} from "./config.utils";
import {webviewAppIds} from "./webview";

export function getSubscriptionSku() {
  const subscriptionSkus = {
    toonmeIos: createSubscriptinSkuData("pro_features_tnmweek_4"),
    toonmeIosNoTrial: createSubscriptinSkuData("pro_features_tnmweek_4_nt"),
    toonmeAndroid: createSubscriptinSkuData("week_2"),
    toonmeAndroidNoTrial: createSubscriptinSkuData("week_2_nt"),
    nppIos: createSubscriptinSkuData("weekly_sub_4"),
    nppIosNoTrial: createSubscriptinSkuData("weekly_sub_4_nt"),
    nppAndroid: createSubscriptinSkuData("week_2"),
    nppAndroidNoTrial: createSubscriptinSkuData("week_2_nt"),
  };

  function choice(android, androidNoTrial, ios, iosNoTrial) {
    // if (window.clientConfig.isBenefitCountry) {
    //   return window.clientConfig.isWebviewAndroid ? android : ios;
    // } else {
      return window.clientConfig.isWebviewAndroid ? androidNoTrial : iosNoTrial;
    // }
  }

  function createSubscriptinSkuData(sku, price = "$4.99") {
    return {
      sku: sku,
      title: "Title for " + sku,
      description: "Description for " + sku,
      price: price,
      period: "P1W",
      trial: "P3D",
    };
  }

  if (isWebviewApp(webviewAppIds.toonme)) {
    return choice(
      subscriptionSkus.toonmeAndroid,
      subscriptionSkus.toonmeAndroidNoTrial,
      subscriptionSkus.toonmeIos,
      subscriptionSkus.toonmeIosNoTrial,
    );
  } else if (isWebviewApp(webviewAppIds.newprofilepic)) {
    return choice(
      subscriptionSkus.nppAndroid,
      subscriptionSkus.nppAndroidNoTrial,
      subscriptionSkus.nppIos,
      subscriptionSkus.nppIosNoTrial,
    );
  } else {
    return choice(
      subscriptionSkus.nppAndroid,
      subscriptionSkus.nppAndroidNoTrial,
      subscriptionSkus.nppIos,
      subscriptionSkus.nppIosNoTrial,
    );
  }
}


export function getOneMorePackInAppSku() {
  const inAppSkus = {
    toonme: createInAppSkuData("tm_avatar_pack_1"),
    newprofilepic: createInAppSkuData("npp_avatar_pack_1"),
    photolab: createInAppSkuData("pl_avatar_pack_1"),
    tooncoin: createInAppSkuData("tc_avatar_pack_1"),
  };

  function createInAppSkuData(sku, price = "$0.99") {
    return {
      sku: sku,
      title: "Title for " + sku,
      description: "Description for " + sku,
      price: price,
    };
  }

  if (isWebviewApp(webviewAppIds.toonme)) {
    return inAppSkus.toonme;
  } else if (isWebviewApp(webviewAppIds.newprofilepic)) {
    return inAppSkus.newprofilepic;
  } else if (isWebviewApp(webviewAppIds.tooncoin)) {
    return inAppSkus.tooncoin;
  } else {
    return inAppSkus.photolab;
  }
}