import {createMd5Token} from "./text";
import md5 from "md5";
import {isWebviewBuild} from "./config.utils";
import IntervalQueue from "./IntervalQueue";

let lastCallbackId = 0;

export const webviewAppIds = {
  photolabFree: 1,
  photolabProHD: 2,
  photolabProIos: 3,
  photolabHuawei: 7,
  newprofilepic: 33,
  toonme: 18,
  toonmeHuawei: 19,
  tooncoin: 34,
}

export const shareProvidersIds = {
  save: 0,
  instagram: 1,
  facebook: 2,
  whatsapp: 3,
  facebookMessenger: 5,
  telegram: 6,
  email: 7,
  sms: 8,
  all: 9,
  snapchat: 11,
  tiktok: 12,
  allSystem: 13,
}

// const installedPackagesMapAndroid = {
//   "com.facebook.orca": "facebookm",
//   "com.facebook.katana": "facebook",
//   "com.instagram.android": "instagram",
//   "com.whatsapp": "whatsapp",
//   "org.telegram.messenger": "telegram",
//   "com.snapchat.android": "snapchat",
//   "com.zhiliaoapp.musically": "tiktok",
// };
//
// const installedPackagesMapIos = {
//   "fb-messenger://": "facebookm",
//   "fb://": "facebook",
//   "instagram://": "instagram",
//   "whatsapp://": "whatsapp",
//   "telegram://": "telegram",
//   "snapchat://": "snapchat",
//   "tiktoksharesdk://": "tiktok",
// };

function createWebviewCallbackFunc(cb) {
  const funcName = "webviewFuncCallback_" + lastCallbackId;
  lastCallbackId++;

  window[funcName] = function() {
    if (cb) {
      cb(...arguments);
    }

    delete window[funcName];
  };

  return funcName;
}

export function webviewBuildCallURL(func, params) {
  const queryPairs = [];

  Object.keys(params).forEach((key) => {
    if (typeof params[key] !== "undefined") {
      queryPairs.push(`${key}=${params[key]}`);
    }
  });

  return `callback:${func}?${queryPairs.join("&")}`;
}

let webviewCallQueue = new IntervalQueue(500);
webviewCallQueue.onRun((data) => {
  console.log("webviewCall", data);
  window.location.href = data;
});

export function webviewCall(func, params) {
  webviewCallQueue.push(webviewBuildCallURL(func, params));
}

export function webviewCallWithCallback(func, params, cb) {
  params.func = createWebviewCallbackFunc(cb);

  webviewCall(func, params);
}

// ---

export function webviewOpenBrowser(url, browser) {
  webviewCall("openblank", {url: encodeURIComponent(url), browser});
}

// ---

export function webviewShare(params, cb) {
  webviewCallWithCallback("nativeShare", params, cb);
}

export function webviewShareFacebook(url, imageUrl, hashtag, options, cb) {
  const params = {
    providers: "[2]",
  };

  if (hashtag) {
    params.hashtag = encodeURIComponent(hashtag);
  }

  if (url) {
    params.url = encodeURIComponent(url);
  }

  if (imageUrl) {
    params.imageUrl = encodeURIComponent(imageUrl);
  }

  webviewShare(Object.assign(params, options || {}), cb);
}

export function webviewShareTelegram(url, imageUrl, hashtag, options, cb) {
  const params = {
    providers: "[6]",
  };

  if (url) {
    params.url = encodeURIComponent(url);
  }

  if (imageUrl) {
    params.imageUrl = encodeURIComponent(imageUrl);
  }

  webviewShare(Object.assign(params, options || {}), cb);
}

export function webviewShareInstagramStory(url, imageUrl, options, cb) {
  const params = Object.assign({
    providers: "[1]",
    url: encodeURIComponent(url),
    imageUrl: encodeURIComponent(imageUrl),
    ig_direct: 1,
    ig_share_to: 'stories',
    close_after_share: 1
  }, options || {});

  webviewShare(params, cb);
}

export function webviewShareInstagramFeed(url, imageUrl, options, cb) {
  const params = Object.assign({
    providers: "[1]",
    url: encodeURIComponent(url),
    imageUrl: encodeURIComponent(imageUrl),
    ig_direct: 1,
    ig_share_to: 'feed',
    close_after_share: 1
  }, options || {});

  webviewShare(params, cb);
}

export function webviewShareSnapchatImage(attachmentUrl, imageUrl, options, cb) {
  const params = Object.assign({
    providers: "[11]",
    imageUrl: encodeURIComponent(imageUrl),
    caption_text: "",
  }, options || {});

  if (attachmentUrl) {
    params.attachment_url = encodeURIComponent(attachmentUrl);
  }

  webviewShare(params, cb);
}

export function webviewShareDownload(imageUrl, cb) {
  const params = {
    providers: "[0]",
    imageUrl: encodeURIComponent(imageUrl)
  };

  webviewShare(params, cb);
}

export function webviewRateApp(func) {
  webviewCallWithCallback("rateApp", func);
}

// ---

export function webviewCheckInstalledApps(packagesArg = {}) {
  if (window.clientConfig.isWebviewIOS) {
    return webviewCheckInstalledAppsIos(packagesArg);
  } else {
    return webviewCheckInstalledAppsAndroid(packagesArg);
  }
}

function webviewCheckInstalledAppsIos(packagesArg) {
  const packages = Object.keys(packagesArg)
    .map((item) => encodeURIComponent(item))
    .join(",");

  return new Promise((resolve) => {
    webviewCallWithCallback("nativeInstalledApps", {packages: `[${packages}]`}, function(result) {
      console.log("webviewCheckInstalledAppsIos response=", result);
      result = JSON.parse(result);

      const installedApps = {};
      Object.keys(result.packages).forEach((key) => {
        installedApps[packagesArg[key]] = result.packages[key] === 1;
      });

      resolve(installedApps);
    });
  });
}

function webviewCheckInstalledAppsAndroid(packagesArg) {
  const packages = Object.keys(packagesArg)
    .map((item) => encodeURIComponent(`"${item}"`))
    .join(",");

  return new Promise((resolve) => {
    webviewCallWithCallback("nativeInstalledApps", {packages: `[${packages}]`}, function(result) {
      const installedApps = {};
      Object.keys(result.packages).forEach((key) => {
        installedApps[packagesArg[key]] = result.packages[key] === 1;
      });

      resolve(installedApps);
    });
  });
}

export function webviewPreloadAd() {
  webviewCall("preloadAd", {});
}

export function webviewShowAd(adShownCb, adClosedCb) {
  webviewCall("showAd", {
    adShown: createWebviewCallbackFunc(adShownCb),
    adClosed: createWebviewCallbackFunc(adClosedCb),
  });
}

// subscription
export function webviewInApp(id) {
  webviewCall("inapp", {id});
}

// inapp
export function webviewConsumable(id) {
  webviewCall("consumable", {id});
}

export function webviewRestore() {
  webviewCall("restore", {});
}

export function webviewShowBanner(
  placement,
  closedCb = () => {},
  data = {}
) {
  const closeParamKey = isWebviewBuild(0, 281) ? "onClose" : "onClosed";

  webviewCall("banner", {
    placement,
    [closeParamKey]: createWebviewCallbackFunc(closedCb),
    ...data
  });
}

export function webviewOnPageFinished() {
  webviewCall("onPageFinished", {});
}

export function webviewGetUserIds() {
  webviewCall("getUserIds", {func: "onGetUserIdsCallback"});
}

export function webviewCheckPhotoPack(id) {
  webviewCall("checkPhotoPack", {id});
}

export function webviewCancelCheck(id) {
  webviewCall("cancelCheck", {id});
}

export function fileToJson(file, hash) {
  const fileParts = file.split(".");

  return {
    id: createMd5Token(file, Math.random()),
    url: file,
    width: 0,
    height: 0,
    extension: fileParts.last(),
    hash: hash || md5(file),
  }
}

export function webviewAnalyticsEvent() {
  if (arguments.length === 0) {
    throw new Error("webviewAnalyticsEvent: no args");
  }

  if (arguments.length > 7) {
    console.warn("webviewAnalyticsEvent: arguments.length > 7");
  }

  const value1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  const value2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
  const value3 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "";
  const value4 = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "";
  const value5 = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : "";
  const value6 = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : "";
  const params = "event=" + arguments[0]
    + "&value1=" + encodeURIComponent(value1)
    + "&value2=" + encodeURIComponent(value2)
    + "&value3=" + encodeURIComponent(value3)
    + "&value4=" + encodeURIComponent(value4)
    + "&value5=" + encodeURIComponent(value5)
    + "&value6=" + encodeURIComponent(value6);

  if (window.appConfig.isDebug) {
    console.log("webviewAnalyticsEvent", JSON.stringify([...arguments]));
  }

  if (window.clientConfig.isWebview) {
    webviewCallByIframe("callback:nativeAnalyticsEvent?" + params);
  }
}

function webviewCallByIframe(url) {
  const WVJBIframe = document.createElement("iframe");
  WVJBIframe.style.display = "none";
  WVJBIframe.src = url;
  document.documentElement.appendChild(WVJBIframe);
  setTimeout(function() {
    document.documentElement.removeChild(WVJBIframe);
  }, 0);
}

export function webviewGetDeeplinkParams(clear = false) {
  return new Promise((resolve) => {
    webviewCall("getDeeplinkParams", {
      func: createWebviewCallbackFunc((data) => resolve(data)),
      clear: clear ? 1 : 0,
    });
  });
}