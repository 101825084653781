/* eslint-disable no-unused-vars */

import groups from "./groups";
import {combo, setCreativesGroup} from "./helpers";
import {isWebviewApp} from "../../utils/config.utils";
import {webviewAppIds} from "../../utils/webview";

function getToonmeCreatives() {
  return setCreativesGroup(groups.toonme, [
    combo([
      {id: 7088, images: [{src: "@7088"}]},
    ]),
    combo([
      {id: 6611, images: [{src: "@6611_3690"}]},
      {id: 3690},
    ]),
    combo([
      {id: 6756, images: [{src: "@6756_2961_1060_1055"}]},
      {id: 2961},
      {id: 1060},
      {id: 1055},
    ]),
    combo([
      {id: 7088, images: [{src: "@7088_6683"}]},
      {id: 6683},
    ]),
  ]);
}

function getNewprofilepicCreatives() {
  return setCreativesGroup(groups.newprofilepic, [
    combo([
      {id: 6935, images: [{src: "@6935_6874"}]},
      {id: 6874},
    ]),
    combo([
      {id: 7333, images: [{src: "@7333_7116"}]},
      {id: 7116},
    ]),
    combo([
      {id: 7083, fallbackId: 7084, images: [{src: "@7083_7540"}]},
      {id: 7540},
    ]),
    combo([
      {id: 7333, images: [{src: "@7333_6472_4652_5076_3746_3734"}]},
      {id: 6472, skipOnMultifaceError: true},
      {id: 4652},
      {id: 5076},
      {id: 3746},
      {id: 3734}
    ]),
  ]);
}

export default {
  getCreatives: function() {
    if (isWebviewApp(webviewAppIds.toonme)) {
      return getToonmeCreatives();
    } else if (isWebviewApp(webviewAppIds.newprofilepic)) {
      return getNewprofilepicCreatives();
    } else {
      return [];
    }
  },
};
